import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
class ReturnRefundPolicy extends Component {
    render() {
        return (
            <div>
                <NavBar />
                <div
                    className="breadcrumb-area breadcrumb-bg"
                    // style={{
                    //     backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`
                    // }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Cancellation and Refund Policy</h1>

                                    <hr className="page-title-line" />
                                    
                                    {/* <ul className="page-breadcrumb">
                                        <li>
                                            <a href="/">Home</a>
                                        </li>
                                        <li>DOI Privacy Policy</li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-wrapper section-space--inner--top--10">
                    <div className="about-section section-space--inner--bottom--50">
                        <div className="container">
                            {/* <h3>Cancellation and Refund Policy</h3> */}
                            <p>Welcome to Destination of India! We are committed to ensuring the satisfaction of our customers. Please read our Cancellation and Refund Policy carefully to understand how we handle cancellations and refunds for our services.</p>
                            {/* <p>A <strong>privacy policy</strong> states how you will respect the privacy of your website users in order to protect and build mutual trust between the company & client. It defines the scope of information on How and what information we gather, how we use it and protect and secure the privacy of the client.</p>
                            <p><strong>Destinations of India</strong> has “adopted customer centric policies & nurture stringent policies adoption across all levels”, The Company takes additional steps to protect <strong>“Personal information”</strong> while using our website.  This privacy policy sets out how <strong>Destinations of India</strong> uses and protects any information that you give to <strong>DOI</strong> when you use this website.</p>
                            <p><strong>DOI</strong> is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.</p>
                            <p><strong>DOI</strong>  may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective <strong>from Oct 1st 2012</strong> date.</p> */}

                        {/* <br /> */}

                            <h3>Cancellation Policy</h3>
                            <p>1. Same-Day Cancellation: You can cancel your order on the same day you made the booking. To cancel, please contact our support team through the provided communication channels on our website. Cancellations made on the same day as the order will be processed immediately, and all money will be fully refunded to your original payment method.</p>
                            <p>2. Cancellation by Destination of India: If a tour, event, or any service is canceled due to circumstances under our control or due to not meeting the minimum group requirement, you will be refunded in full within 4-5 days of the cancellation notice.</p>

                            <h3>Refund Policy</h3>
                            <p>1.  Eligibility for a Refund: Refunds are applicable only to cancellations as per the above policy. Our team will inspect the details of your order to ensure eligibility for a refund.</p>
                            <p>2. Processing Refunds: Once your cancellation is confirmed, we will initiate a refund to your original method of payment. You will receive the credit within a certain amount of days, depending on your card issuer's policies.</p>

                            <h4>How to Initiate a Return</h4>
                            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                                <li>Contact Us: Reach out to our support team via the contact information provided on our website.</li>
                                <li>Provide Necessary Details: Include your order number, contact information, and your request for a refund.</li>
                                <li>Confirmation: Our support team will review the information, confirm the eligibility, and process your request.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu />
            </div>
        );
    }
}

export default ReturnRefundPolicy;
