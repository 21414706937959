import React, {  Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import Modal from "./myComponents/Modal";
import ReturnRefundPolicy from "./myComponents/ReturnRefundPolicy";
// import EventDetails from "./myComponents/EventDetails";

const HomeOne = lazy(() => import("./home/HomeOne"));
const About = lazy(() => import("./pages/About"));
const Services = lazy(() => import("./service/Services"));
const ServiceDetailsLeftSidebar = lazy(() => import("./service/ServiceDetailsLeftSidebar"));
const ServiceDetailsRightSidebar = lazy(() => import("./service/ServiceDetailsRightSidebar"));
const Projects = lazy(() => import("./project/Projects"));
const ProjectDetails = lazy(() => import("./project/ProjectDetails"));
const BlogLeftSidebar = lazy(() => import("./blog/BlogLeftSidebar"));
const BlogRightSidebar = lazy(() => import("./blog/BlogRightSidebar"));
const BlogDetailsLeftSidebar = lazy(() => import("./blog/BlogDetailsLeftSidebar"));
const BlogDetailsRightSidebar = lazy(() => import("./blog/BlogDetailsRightSidebar"));
const Contact = lazy(() => import("./pages/Contact"));

// custom path
const DisclaimerPolicy = lazy(() => import("./myComponents//DisclaimerPolicy"));
const PrivacyPolicy = lazy(() => import("./myComponents//PrivacyPolicy"));
const TermsConditionsPolicy = lazy(() => import("./myComponents//TermsConditionsPolicy"));
// const IncredibleIndia = lazy(() => import("./myComponents//IncredibleIndia"));
const Registration = lazy(() => import("./myComponents//Registration"));
const Login = lazy(() => import("./myComponents/Login"));
const Dashboard = lazy(() => import("./myComponents/Dashboard"));

const PreConference = lazy(() => import("./myComponents/PreConference"));
const OnsiteConference = lazy(() => import("./myComponents/OnsiteConference"));
const PostConference = lazy(() => import("./myComponents/PostConference"));
const OurServices = lazy(() => import("./myComponents/Content/OurServices"));
const OurTeam = lazy(() => import("./myComponents/Content/OurTeam"));
const Conferences = lazy(() => import("./myComponents/InstitutionalEvents/Conferences"));
const Meetings = lazy(() => import("./myComponents/InstitutionalEvents/Meetings"));
const Symposium = lazy(() => import("./myComponents/InstitutionalEvents/Symposium"));
const WhatWeDo = lazy(() => import("./myComponents/InstitutionalEvents/WhatWeDo"));
const CoreServices = lazy(() => import("./myComponents/Content/CoreServices"));
const CorporateEvents = lazy(() => import("./myComponents/Content/CorporateEvents"));
const DoiIncentivesHolidays = lazy(() => import("./myComponents/Content/DoiIncentivesHolidays"));
const KnowIndia = lazy(() => import("./myComponents/Content/KnowIndia"));
const KnowKarnataka = lazy(() => import("./myComponents/Content/KnowKarnataka"));
const CorporateGifts = lazy(() => import("./myComponents/Content/CorporateGifts"));
const OurWorks = lazy(() => import("./myComponents/Content/OurWorks"));
const StageLayouts = lazy(() => import("./myComponents/Content/StageLayouts"));
const PartnerNetwork = lazy(() => import("./myComponents/Content/PartnerNetwork"));
const News = lazy(() => import("./myComponents/Content/News"));
const WhatWeDoCE = lazy(() => import("./myComponents/Content/corporate-events/WhatWeDo"));
const WhatWeDoIH = lazy(() => import("./myComponents/Content/doi-incentives-holidays/WhatWeDo"));
const EventDetails = lazy(() => import("./myComponents/EventDetails"));

const BlurBackground = ({ show }) => {
  return (
    show ? (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.7)",
          backdropFilter: "blur(10px)",
          zIndex: 999,
        }}
      />
    ) : null
  );
};


const NoMAtch = lazy(() => import("./pages/404"));


const App = () => {

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showBlur, setShowBlur] = useState(false);

  const ModalRouteListener = () => {
    const location = useLocation();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (!location.pathname.includes("abstract-form") && !location.pathname.includes("online-registration")) {
        localStorage.removeItem('event-details');
      }
  
      const token = localStorage.getItem('token');
      if (location.pathname === "/login") {
        if (token) {
          navigate("/admin-dashboard"); 
        } else {
          setShowLoginModal(true); 
          setShowBlur(true);
        }
      } else {
        setShowLoginModal(false);
        setShowBlur(false);
      }
    }, [location, navigate]);
    return null;
  };
  

  const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    return token ? children : <Navigate to="/login" replace />;
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setShowLoginModal(false);
    }
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL}>
        <Suspense fallback={<div />}>
            <Routes>
                <Route path="/" element={<HomeOne />} />
                <Route path="/home" element={<HomeOne />} />

                <Route path="/home-one" element={<HomeOne />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/service-details-left-sidebar" element={<ServiceDetailsLeftSidebar />} />
                <Route path="/service-details-right-sidebar" element={<ServiceDetailsRightSidebar />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/project-details" element={<ProjectDetails />} />
                <Route path="/blog-left-sidebar" element={<BlogLeftSidebar />} />
                <Route path="/blog-right-sidebar" element={<BlogRightSidebar />} />
                <Route path="/blog-details-left-sidebar" element={<BlogDetailsLeftSidebar />} />
                <Route path="/blog-details-right-sidebar" element={<BlogDetailsRightSidebar />} />
                <Route path="/contact-us" element={<Contact />} />

                {/* custom path */}
                <Route path="/disclaimer-policy" element={<DisclaimerPolicy />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/cancellation-and-refund-policy" element={<ReturnRefundPolicy />} />
                <Route path="/terms-conditions-policy" element={<TermsConditionsPolicy />} />
                {/* <Route path="/incredible-india" element={<IncredibleIndia />} /> */}

                <Route path="abstract-form" element={<Registration />} />
                <Route path="online-registration/:eventId" element={<Registration />} />

                <Route path="/admin-dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                <Route path="/pre-conference" element={<PreConference />} />
                <Route path="/onsite-conference" element={<OnsiteConference />} />
                <Route path="/post-conference" element={<PostConference />} />
                <Route path="/our-services" element={<OurServices />} />
                <Route path="/our-team" element={<OurTeam />} />
                <Route path="/institutional-events" element={<Conferences />} />
                <Route path="/institutional-meetings" element={<Meetings />} />
                <Route path="/symposium" element={<Symposium />} />
                <Route path="/what-we-do" element={<WhatWeDo />} />
                <Route path="/core-services" element={<CoreServices />} />
                <Route path="/corporate-events" element={<CorporateEvents />} />
                <Route path="/DOI-incentives-holidays" element={<DoiIncentivesHolidays />} />
                <Route path="/know-india" element={<KnowIndia />} />
                <Route path="/know-karnataka" element={<KnowKarnataka />} /> 
                <Route path="/corporate-gifts" element={<CorporateGifts />} />
                <Route path="/our-works" element={<OurWorks />} />
                <Route path="/stage-layouts" element={<StageLayouts />} />
                <Route path="/partner-network" element={<PartnerNetwork />} />
                <Route path="/news" element={<News />} />  
                <Route path="/corporate-events-what-we-do" element={<WhatWeDoCE />} />
                <Route path="/DOI-incentives-holidays-what-we-do" element={<WhatWeDoIH />} />

                <Route path="/event-details/:eventId" element={<EventDetails />} />

                {/*  */}
                <Route path="*" element={<NoMAtch />} />
                
            </Routes>

            <BlurBackground show={showBlur} />

            {!localStorage.getItem('token') && showLoginModal && (
              <Modal show={showLoginModal} onClose={() => setShowLoginModal(false)} modalContent={'login-form'}>
                <Login />
              </Modal>
            )}

            <ModalRouteListener />

        </Suspense>
      </Router>
  )
}

export default App
