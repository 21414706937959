import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import ReactLoading from "react-loading";

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting,setIsSubmitting]=useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate(); 

  const togglePasswordVisibility = () => {
      setIsPasswordVisible(!isPasswordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError("Email and Password are required.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }

    setIsSubmitting(true)
    setError('');
  
    try {
      const response = await fetch('https://us-central1-destination-india-113c1.cloudfunctions.net/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          password: password,
          username: 'admin'
        })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      console.log(data);
      localStorage.setItem('token', data.token);
      setIsSubmitting(false)
      navigate('/admin-dashboard');
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setIsSubmitting(false)
      setError('Login failed. Please try again.');
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  };
  

  return (
    <div className="wrapper signIn">
      <div className="form">
        <div className="heading">LOGIN</div>
        {error && <div className="message-error">{error}</div>}
        {isSubmitting && !error && (
          <div className="loading-overlay">
            <ReactLoading
              type="spinningBubbles"
              color="#000"
              height={30}
              width={30}
            />
          </div>
        )}
        <form onSubmit={handleLogin}>
          <div>
            <label htmlFor="e-mail">E-Mail</label>
            <input
              type="email"
              id="e-mail"
              placeholder="Enter your mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="password-input">
            <label htmlFor="password">Password</label>
            <div className="password-container">
                <input
                    type={isPasswordVisible ? "text" : "password"}
                    id="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <i 
                    className={`password-icon fa ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}`} 
                    onClick={togglePasswordVisibility}
                ></i>
            </div>
          </div>

          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Login ..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
}
