import React, { Component } from "react";
import { animateScroll as scroll } from "react-scroll";
import Modal from "../myComponents/Modal";
import Login from "../myComponents/Login";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

   toggleModal = () => {
    console.log("Toggling modal");
    this.setState({ showModal: !this.state.showModal });
  };

  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector("nav");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? document.getElementById("scroll-top").classList.add("show")
      : document.getElementById("scroll-top").classList.remove("show");
  }

  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    return (
      <div>
        {/*====================  footer area ====================*/}
        <div className="footer-area dark-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="footer-content-wrapper section-space--inner--50" style={{marginTop:'-10px', marginBottom:'-40px'}}>
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-12">
                      {/* footer intro wrapper */}
                      <div className="footer-intro-wrapper">
                        <div className="footer-logo">
                          <a href={`${process.env.PUBLIC_URL}/`}>
                            <img
                              src="assets/img/logo/DoI-LogoWhite.png"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="footer-desc">
                          Destinations of India (DOI): is a premier Travel & Tour company, which founded its roots in Bangalore in 2012. DOI was set up by a team of the leading experts with four decades of experience in the Travel & Tourism industry.
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-1 offset-xl-1 col-lg-3 col-md-4">
                      {/* footer widget */}
                      <div className="footer-widget">
                        <h4 className="footer-widget__title">MENU</h4>
                        <ul className="footer-widget__navigation">
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/`} className="no-style-link footer-link-style">Home</a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/about-us`} className="no-style-link footer-link-style">
                              About Us
                            </a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/our-services`} className="no-style-link footer-link-style">Our Services</a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/news`} className="no-style-link footer-link-style">News</a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/contact-us`} className="no-style-link footer-link-style">
                              Contact Us
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-3 offset-xl-1 col-lg-3 col-md-4">
                      {/* footer widget */}
                      <div className="footer-widget">
                        <h4 className="footer-widget__title">USEFUL LINKS</h4>
                        <ul className="footer-widget__navigation">
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/disclaimer-policy`} className="no-style-link footer-link-style">
                              Disclaimer
                            </a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/privacy-policy`} className="no-style-link footer-link-style">
                              Privacy Policy
                            </a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/cancellation-and-refund-policy`} className="no-style-link footer-link-style">
                              Cancellation and Refund Policy
                            </a>
                          </li>
                          <li>
                            <a href={`${process.env.PUBLIC_URL}/terms-conditions-policy`} className="no-style-link footer-link-style">
                            Terms & Conditions
                          </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-2 offset-xl-0 col-lg-3 col-md-4">
                      {/* footer widget */}
                      <div className="footer-widget mb-0">
                        {/* <h4 className="footer-widget__title">CONTACT US <span>&nbsp;&nbsp;<i className="bi bi-person-circle" onClick={this.toggleModal}  style={{cursor:'pointer'}}></i></span></h4> */}
                        <h4 className="footer-widget__title">CONTACT US</h4>
                        <h4 style={{color:'white'}}>Destinations of India </h4>
                        {/* <Modal show={this.state.showModal} onClose={this.toggleModal} /> */}
                        <Modal show={this.state.showModal} onClose={this.toggleModal} modalContent={'login-form'}>
                          <Login />
                      </Modal>

                        <div className="footer-widget__content">
                          <p className="address" style={{color:'#c7c7c7'}}>
                            {/* Your address goes here, street Crossroad123. */}                         
                            No 55, 2nd Floor , 1st Main Road ,Thyagarajanagar Bangalore- 560028
                          </p>
                          <ul className="contact-details">
                            <li>
                              <span>Land Line:</span>080-26766583
                            </li>
                            <li>
                              <span>Hand Phone:</span>9844550766
                            </li>
                            <li>
                              <span>Email:</span>doiconferences@gmail.com <br /><span style={{marginLeft:'50px'}}>prasad@doiconferences.com</span>
                            </li>
                            {/* <li>
                              <a href="//facebook.com" className="social-links" style={{padding:'15px', fontSize:'20px'}}><i className="zmdi zmdi-facebook" /></a>
                              <a href="//twitter.com" className="social-links" style={{padding:'15px', fontSize:'20px'}}><i className="zmdi zmdi-twitter" /></a>
                              <a href="//linkedin.com" className="social-links" style={{padding:'15px', fontSize:'20px'}}><i className="zmdi zmdi-linkedin-box" /></a>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright-wrapper">
            <div className="footer-copyright text-center">
              <p className="copyright-text" style={{color:'#c7c7c7'}}>
                {/* &copy; Destinations of India (DOI) {new Date().getFullYear()} Made With <i className="fa fa-heart"></i> By
                Codedotart */}
                &copy; Destinations of India {new Date().getFullYear()} | Powered by <a href="https://codedotart.com/" target="_blank" rel="noopener noreferrer" className="codedotart-link">CodeDotArt</a>
              </p>

            </div>
          </div>
        </div>
        {/*====================  End of footer area  ====================*/}
        {/*====================  scroll top ====================*/}
        <button
          className="scroll-top"
          id="scroll-top"
          onClick={this.scrollToTop}
        >
          <i className="ion-android-arrow-up" />
        </button>
        {/*====================  End of scroll top  ====================*/}
      </div>
    );
  }
}

export default Footer;
