import React from "react";
import Login from "./Login";
import './Modal.css';
import { useNavigate } from "react-router-dom";

const Modal = ({ show, onClose, modalContent  }) => {
    console.log("Modal show prop:", show, modalContent );

    const navigate = useNavigate();

    if (!show) {
        return null;
    }

    const closeModal = (e) => {
        e.stopPropagation();
        navigate('/');
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={closeModal}>
                    <i className="bi bi-x-lg"></i>
                </span>
                <Login />
            </div>
        </div>
    );
};

export default Modal;

  

